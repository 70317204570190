import {
  CircularProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelOrder from './CancelOrder/CancelOrder';
import Notes from '../../General/Notes';
import DetailProductTable from './DetailProductTable';
import DetailModal from '../../General/DetailOrderModal/DetailModal';
import { getOrderData, send_purchasing } from 'service/api';
import PaginationFilter from '../../General/PaginationFilter';
import AdjustmentModal from './Adjustment/AdjustmentModal';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { getUser } from 'helpers/parseJWT';
import swal from 'sweetalert';
import Filters from 'components/General/Filters';
import BadgeLevel from 'components/General/BadgeLevel';
import { censorPhoneNumber } from 'helpers/cencorPhoneNumber';
import { useTranslation } from 'react-i18next';
import FilterPurchasing from 'components/General/FilterPurchasing';
import { useRecoilState } from 'recoil';
import { getLangcode } from 'atom';
import BrandLogo from 'components/General/BrandLogo';

export default function NewOrderTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  const [update, setUpdate] = useState(false);
  const [idSo, setIdSo] = useState('');
  const [toko, setToko] = useState('all');
  const [auto, setAuto] = useState('');
  const [canceled, setCanceled] = useState(null)
  const [oem, setOem] = useState(null)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [date, setDate] = useState({
    start: '',
    end: '',
    sorting: 'asc'
  });
  const [isOpen, setIsOpen] = useState(false);
  const [sla, setSla] = useState('');
  const [pageInfo, setPageInfo] = useState({});
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [langcodes, setLangcodes] = useRecoilState(getLangcode);
  const { t } = useTranslation();
  const [isFilterApplied, setIsFilterApplied] = useState(false);

    

  const fetchOrderData = async (limit, page, id, toko, auto, canceled, oem, slaProps, langcode) => {
    setIsLoading(true);
    // if (auto == null) {
    //   auto = false;
    // }
    // const dataSla = slaProps || sla

    const tokoParams = toko !== '' && toko !== null ? `toko=${toko}&` : '';
    const autoParams = auto !== null && auto !== '' ? `auto=${auto}&` : '';
    const idParams = id && id !== '' ? `id=${id}&` : '';
    const startParams = date.start !== '' ? `start=${date.start}&` : '';
    const endParams = date.end !== '' ? `end=${date.end}&` : '';
    const sortParams = date.sorting && date.sorting !== '' ? `sort=${date.sorting}&` : '';
    const canceledParams = canceled && canceled !== '' ? `canceled=${canceled}&` : '';
    const oemParams = oem && oem !== '' ? `oem=${oem}` : '';
    const slaParams = slaProps !== '' && slaProps !== undefined && slaProps !== null ? `sla=${slaProps}&` : '';
    const langcodePrams = langcodes && langcodes !== '' ? `langcode=${langcodes}&` : '';


    const params =
      `page=${page}&limit=${limit}&` +
      tokoParams +
      autoParams +
      idParams +
      startParams +
      endParams +
      sortParams +
      canceledParams +
      oemParams +
      langcodePrams +
      slaParams;

    // let params = null
    // if (toko === '1688') {
    //   params = new URLSearchParams({ limit, page, id, start, end }).toString();
    // } else {
    //   params = new URLSearchParams({ limit, page, toko, id, start, end }).toString();
    // }

    const data = await getOrderData(params);
    if (data?.status === 200) {
      const newFormat = [];
      for (
        let i = 0;
        i < data?.data?.data?.customer?.collection?.length;
        i++
      ) {
        const customer = data?.data?.data?.customer?.collection[i];
        const order = data?.data?.data?.orders.collection[i];
        const idOrder = data?.data?.data?.idOrders?.collection[i];
        const finance = data?.data?.data?.finance?.collection[i];
        newFormat.push({ customer, order, idOrder, finance });
      }
      setDataOrder(newFormat);
      setPageInfo({
        dataInPage: data.data.dataInPage,
        totalData: data.data.totalData,
        totalPage: data.data.totalPage,
      });
    } else {
      setDataOrder([])
      setPageInfo({})
    }
    setIsLoading(false);
    setIsFilterApplied(false)
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    let slaValue = query.get('sla');
    let ids = query.get('id')

    if (search) {
      setIdSo(query.get('id'))
      setToko(query.get('toko'))
      setAuto(query.get('auto'))
      setCanceled(query.get('canceled'))
      setSla(query.get('sla'))
      setOem(oem)
      if (slaValue === "true" || slaValue === "false" || ids) {
        fetchOrderData(limit, page, query.get('id'), query.get('toko'), query.get('auto'), query.get('canceled'), query.get('oem'), slaValue, langcodes);
      } else {
        fetchOrderData(limit, page, query.get('id'), query.get('toko'), query.get('auto'), query.get('canceled'), query.get('oem'), slaValue, langcodes);
      }
    } else {
      setSla(slaValue);
      if (isFilterApplied) {
        fetchOrderData(limit, page, idSo, toko, auto, canceled, oem, slaValue, langcodes);
      } else {
        fetchOrderData(limit, page, idSo, toko, auto, canceled, oem);
      }
    }
  }, [search, update, limit, page])

  const searchOrderByFilters = (id, toko, auto) => {
    setLimit(5);
    setPage(1);
    let slaValue = query.get('sla');
    setIsOpen(false)
    fetchOrderData(5, 1, id, toko, auto, canceled, oem, slaValue, langcodes);
    setIsFilterApplied(true)
  };

  const sendPurchasing = async (id, toko) => {
    swal({
      title: 'Sending data..',
      onBeforeOpen() {
        swal.showLoading()
      },
      onAfterClose() {
        swal.hideLoading()
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      closeOnEnter: false,
      buttons: false
    })
    const response = await send_purchasing(id, toko)
    if (response.status === 200) {
      swal('Success', '', 'success')
        .then(() => {
          if (dataOrder.length === 1) {
            setPage(prev => prev - 1)
            fetchOrderData(5, page - 1);
          } else {
            setUpdate(prev => !prev)
          }
        })
      // swal.close()
    } else {
      swal('Error', response.message, 'error')
    }
  }

  const renderLoading = isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        padding: '10px',
        borderRadius: 2,
        backgroundColor: 'white',
        marginBottom: 1,
      }}>
      <div className="flex space-x-3 items-center w-full bg-blue-100 p-3 rounded-md">
        <CircularProgress size={20} />
        <p className="text-gray-500 text-sm ">Updating data ...</p>
      </div>
    </Box>
  ) : null;

  const Row = ({ rowData }) => {
    const [open, setOpen] = useState(false);

    const download = (logos) => {
      try {
        const a = logos.map(logo => "gambar[]=" + logo)
        const join = a.join("&")
        return "https://control-panel.ocistok.com/download.php?" + join
      } catch (error) {
        console.log('Data not found')
      }
    };

    return (
      <>
        <TableRow style={{ display: 'flex' }}>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '15%',
            }}>
            <div className="text-sm flex h-full flex-col justify-between">
              <div>
                <div className="flex items-center justify-between space-x-2">
                  <DetailModal id={rowData?.idOrder?.id_so} />
                  <div className="bg-yellow-500 text-white px-2 py-1 rounded-bl-lg">{rowData?.idOrder?.toko || "Custom"}</div>
                </div>
                <div>
                  {typeof rowData?.idOrder?.pcm === "string" && rowData?.idOrder?.pcm.trim() !== "" && (
                    <div className='text-base text-blue-800 font-semibold uppercase'>
                      ({rowData?.idOrder?.pcm})
                    </div>
                  )}
                </div>
                <div>
                  {rowData?.idOrder?.paymentDate && (
                    <p>{t("Payment Date")} : {rowData?.idOrder?.paymentDate}</p>
                  )}
                  {rowData?.idOrder?.keterangan && (
                    <p>{t("Note")} :{rowData?.idOrder?.keterangan}</p>
                  )}
                </div>
                <div className="flex space-x-2 items-center my-2">
                  {rowData?.idOrder?.airplane ? (
                    <>
                      <FlightTakeoffIcon />
                      <p className="text-xs font-semibold">Air Freight</p>
                    </>
                  ) : (
                    <>
                      <DirectionsBoatIcon />
                      <p className="text-xs font-semibold">Sea Freight</p>
                    </>
                  )}
                </div>
                {rowData?.idOrder?.auto &&
                  <div className="w-full text-xs text-center mb-2 line-clamp-1" title={rowData?.idOrder?.auto}>{rowData?.idOrder?.auto}</div>
                }
                {rowData?.idOrder?.mytask &&
                  <div className={`${rowData?.idOrder?.mytask === "Under Review" ? "bg-yellow-500" : " bg-blue-500"} px-2 py-1 text-xs text-white text-center rounded-md mb-2`}>{rowData?.idOrder?.mytask}</div>
                }
                {rowData?.finance?.income >= 3000000 &&
                  <div className="mb-2 task-status px-2 py-1 text-xs text-white text-center bg-green-500 rounded-md">Big Order</div>
                }

                {rowData?.idOrder?.is_oem && <div className="task-status px-2 py-1 text-xs text-white text-center bg-red-500 rounded-md">ODM</div>}

                {rowData?.idOrder?.notes &&
                  rowData?.idOrder?.notes?.slice(0, 3).map((note, id) => (
                    <div
                      className="flex items-center text-xs justify-between"
                      key={id}>
                      <em className="line-clamp-1 w-14">{note.date}</em>
                      <p className="line-clamp-1">{note.note}</p>
                      <p>{note.sales}</p>
                    </div>
                  ))}
              </div>
              {getUser().roles?.includes('admin') &&
                <div className="text-center">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      setOpen(!open);
                    }}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                  <span className="text-xs">{t("Details")}</span>
                </div>
              }
            </div>
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '17%',
            }}>
            <div className="text-sm">
              <p>{rowData?.customer?.name}</p>
              <p className="break-words line-clamp-1">
                {rowData?.customer?.email}
              </p>
              <p>{getUser().isBlur ? censorPhoneNumber(rowData?.customer?.phone) : rowData?.customer?.phone}</p>
              <p className="line-clamp-3">{rowData?.customer?.address}</p>
              <em className="line-clamp-1">{rowData?.customer?.courier}</em>
              <BadgeLevel level={rowData?.idOrder?.level} />
              {rowData.idOrder.is_adjustment &&
                <div className='flex mt-1 gap-1'>
                  <div className='rounded-xl bg-black text-white px-2 p-1'>Adjusment</div>
                  <div className={`${rowData.idOrder.adjustment_status === "expired" ? 'bg-[#E5CDCE] text-red-500' :
                    rowData.idOrder.adjustment_status === "paid" ? 'bg-[#C6E0D2]  text-green-500' :
                      rowData.idOrder.adjustment_status === "unpaid" ? 'bg-[#D2D0E6]  text-blue-500' : undefined} rounded-lg px-3 text-xs flex items-center font-bold`}>{rowData.idOrder.adjustment_status}</div>
                </div>
              }
              <div className="flex flex-col gap-1 mt-1">
              <BrandLogo dataSource={rowData?.idOrder?.source} />
               
              </div>
            </div>
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '28%',
            }}>
            <div className="flex flex-col items-center  space-y-2">
              {rowData?.order?.slice(0, 3).map((order, id) => {
                return (
                  <div className="text-sm space-y-1 w-full" key={id}>
                    <p className="line-clamp-1">{order.name}</p>
                    <div className="flex items-center justify-between font-semibold">
                      <p>{order.variant}</p>
                      <p>{order.qty} pcs</p>
                      <NumberFormat
                        value={order.customerPrice}
                        displayType={'text'}
                        className="text-sm"
                        thousandSeparator={true}
                        prefix={'IDR '}
                        decimalScale={2}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '20%',
            }}>
            <div className="text-sm space-y-2">
              {rowData?.finance?.income ? (
                <div className="flex justify-between">
                  <span>{t("Total Income")}:</span>
                  <span>{rowData?.finance?.income.toLocaleString('id-ID')}</span>
                </div>
              ) : null}

              <div>
                <div className="flex justify-between">
                  <span>{t("Total Expense")}:</span>
                  <span>
                    {rowData?.finance?.expense?.toLocaleString('id-ID')}
                  </span>
                </div>
                <div className="text-xs">(without Indonesian logistic)</div>
              </div>

              <hr />
              {rowData?.finance?.percentage && (
                <div className="flex justify-between">
                  <span>{t("Percentage")}:</span>
                  <span>{rowData?.finance?.percentage} %</span>
                </div>
              )}

              <div className="profit">
                <div className="flex justify-between">
                  <span>{t("Profit")}:</span>
                  <span
                    className={`${rowData?.finance?.income > rowData?.finance?.expense
                      ? 'text-green-500'
                      : 'text-red-500'
                      } font-semibold`}>
                    {rowData?.finance?.profit?.toLocaleString('id-ID')}
                  </span>
                </div>
              </div>

              {rowData?.idOrder?.is_packing_kayu ?
                <>
                  <hr />
                  <div className="flex">
                    <span className='px-2 py-1 text-xs text-white bg-red-500 rounded-md'>Wooden Packing</span>
                  </div>
                </>
                : null}

              {rowData?.idOrder?.adjustment_status === "open" ||
                rowData?.idOrder?.adjustment_status === "unpaid" ? (
                <>
                  <hr />
                  <div className='flex gap-1'>
                    <span className="py-1 text-red-600 font-semibold">
                      {t("Warning")}:
                    </span>
                    <div className='border rounded-md text-orange-500 border-orange-500 w-fit flex items-center px-2'>Waiting Paid Adjusment</div>
                  </div>
                </>
              ) : rowData?.idOrder?.warning ? (
                <>
                  <hr />
                  <span className="py-1 text-red-600 font-semibold">
                    {t("Warning")}: {rowData?.idOrder?.warning_message}
                  </span>
                </>
              ) : undefined}
              {/* 
              {rowData?.idOrder?.warning && (
                <>
                  <hr />
                  <span className="py-1 text-red-600 font-semibold">
                    {t("Warning")}: {rowData?.idOrder?.warning_message}
                  </span>
                </>
              )} */}

              {rowData?.idOrder?.last_update && (
                <>
                  <hr />
                  <span className="py-1">
                    {t("Last Update")}: {rowData?.idOrder?.last_update}
                  </span>
                </>
              )}

              {rowData?.idOrder?.is_oem && (
                <div className='cursor-pointer'>
                  {/* <button onClick={download(rowData?.idOrder?.logo)} className='px-2 py-1 rounded bg-gray-400 text-white'>Download</button> */}
                  <a href={download(rowData.idOrder?.logo)} className='px-2 py-1 rounded bg-gray-400 text-white'>Download</a>
                </div>
              )}

            </div>
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '20%',
            }}>
            <div className="space-y-2 mx-3">
              {getUser().roles.includes('admin') &&
                <AdjustmentModal
                  id_so={rowData?.idOrder?.id_so}
                  setUpdate={setUpdate}
                  dataOrder={rowData?.order}
                />
              }
              <Notes
                id={rowData?.idOrder?.id_so}
                id_group={rowData?.idOrder?.id_group}
                endpoint="new_orders"
                task_status={rowData?.idOrder?.mytask}
                totalNotes={rowData?.idOrder?.total_notes}
                totalNotesByRole={rowData?.idOrder?.total_notes_group_by_role}
                setUpdate={setUpdate}
                dataOrder={rowData?.order}
              />
              {getUser().roles.includes('admin') &&
                <CancelOrder
                  id={rowData?.idOrder?.id_so}
                  dataOrder={rowData?.order}
                  email={rowData?.customer?.email}
                  setUpdate={setUpdate}
                />
              }
              {["1688"].includes(rowData?.idOrder?.toko) &&
                <div
                  className="py-2 px-5 border text-blue-500  text-sm border-blue-500 rounded-md text-center cursor-pointer"
                  onClick={() => sendPurchasing(rowData?.idOrder?.id_so, rowData?.idOrder?.toko)}
                >
                  {t("Order")}
                </div>
              }
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Detail Product
                </Typography>
                <DetailProductTable
                  dataOrder={rowData?.order}
                  link={rowData?.idOrder?.link}
                  logoVariant={rowData}
                  id_so={rowData?.idOrder?.id_so}
                  is_oem={rowData?.idOrder?.is_oem}
                  notesProduct={rowData?.idOrder?.notes}
                  setUpdate={setUpdate}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      {renderLoading}
      <FilterPurchasing
        filterToko={true}
        idSo={idSo}
        toko={toko}
        setToko={setToko}
        auto={auto}
        setAuto={setAuto}
        date={date}
        setDate={setDate}
        setUpdate={setUpdate}
        actionSubmit={searchOrderByFilters}
        setIdSo={setIdSo}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <div className="flex items-center justify-between bg-white p-2 px-3 rounded-md my-2">
        <div className="flex items-center gap-x-3 text-sm font-semibold">

          {/* <Filters
            filterToko={true}
            idSo={idSo}
            toko={toko}
            setToko={setToko}
            auto={auto}
            setAuto={setAuto}
            date={date}
            setDate={setDate}
            setUpdate={setUpdate}
            actionSubmit={searchOrderByFilters}
          /> */}
        </div>
        <div className='flex items-center gap-2'>
          {pageInfo?.dataInPage
            ? `${t("Showing data")} ${pageInfo?.dataInPage} ${t("of")} ${pageInfo?.totalData}`
            : null}
          <PaginationFilter
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalPage={pageInfo.totalPage}
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ display: 'flex' }}>
              <TableCell
                style={{
                  width: '15%',
                }}>
                {t("ID Order")}
              </TableCell>
              <TableCell
                style={{
                  width: '17%',
                }}>
                {t("Customer")}
              </TableCell>
              <TableCell
                style={{
                  width: '28%',
                }}>
                {t("Order")}
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}>
                {t("Finance")}
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}>
                {t("Action")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataOrder.length > 0 ? (
              <>
                {dataOrder.map((row, id) => (
                  <Row rowData={row} key={id} />
                ))}

                {dataOrder.length <= 3 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ height: '50vh' }}></TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  style={{ height: '70vh' }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}