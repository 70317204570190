import React, { useEffect, useRef, useState } from 'react';
import { filterNewCustomerGolang } from 'service/api';
import swal from 'sweetalert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getUser } from 'helpers/parseJWT';
import AssigneSales from './AssigneSales';
import LangcodeSwitch from 'components/General/LangcodeSwitch';
import { useHistory } from "react-router-dom";

const FilterNewCustomer = ({
  isUseAssigneSales,
  filterBy,
  setDataFiltered,
  setIsFiltered,
  setIsLoading,
  isLoading,
  dataSales,
  setdataSales,
  selectData,
  setSelectData,
  totalPages,
  setTotalPages,
  isPage,
  setIsPage,
  fetchDataNewCustomer,
  setChangeData,
  showModalAssigneSales,
  setShowModalAssigneSales,
  showModalResponse,
  setShowModalResponse
}) => {
  //   const [selectData, setSelectData] = useState({
  //     selected: 'email',
  //     email: '',
  //     phone: '',
  //     start: '',
  //     end: '',
  //     limit: 50,
  //   });
  const isFirstRun = useRef(true);
  const history = useHistory()
  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    name === 'start' && setIsPage(1);

    name === 'selected' && setIsPage(1);
    name === 'selected' && setSelectData({
      ...selectData,
      email: '',
      phone: '',
      start: '',
      end: '',
      sales: '',
      limit: 50,
    });

    setSelectData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const submitFilterHandler = async () => {
    const { start, end, email, limit, phone, sales, selected } = selectData;
    if (email !== '' || phone !== '' || start !== '' || end !== '' || sales !== '') {
      if (selected === 'tanggal' && limit === 0) {
        swal('info', `Masukan Limit Data yang di inginkan`, 'info');
      } else {
        fetchDataNewCustomer()
      }
    } else {
      swal('warning', `Please Fill Input Filter`, 'warning');
    }
  };

  // useEffect(() => {
  //   if (isFirstRun.current) {
  //     isFirstRun.current = false;
  //     return;
  //   }
  //   submitFilterHandler();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isPage]);

  const maxDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  );

  const disabledPrev = isPage === 1 || isLoading === true;
  const disabledNext =
    isPage === totalPages || isPage === totalPages + 1 || isLoading === true;

  return (
    <>
      <div className='flex justify-between bg-white gap-5 p-2 rounded-md mb-2 items-center text-sm md:overflow-y-hidden overflow-y-scroll'>
        <div className='flex items-center gap-2'>
          <div className=''>Filter By : </div>
          <div className='flex items-center gap-2'>
            <select
              value={selectData.selected}
              onChange={handleSelectChange}
              name='selected'
              className='border border-gray-200 p-2 rounded-md focus:outline-blue variant-scroll'>
              {filterBy.includes('email') && <option value='email'>Email</option>}
              {filterBy.includes('phone') && <option value='phone'>Phone Number</option>}
              {filterBy.includes('tanggal') && <option value='tanggal'>Tanggal</option>}
              {filterBy.includes('sales') && ['admin', 'supervisor'].some((role) => getUser()?.roles?.includes(role)) && <option value='sales'>Sales</option>}
            </select>

            {selectData?.selected === 'email' && (
              <>
                <input
                  type='text'
                  placeholder='Email Keyword'
                  name='email'
                  value={selectData.email}
                  onChange={handleSelectChange}
                  className='p-2 rounded-md w-60 border border-gray-200 focus:outline-blue cursor-pointer'
                />
              </>
            )}

            {selectData?.selected === 'phone' && (
              <>
                <input
                  type='text'
                  placeholder='Masukan Nomor phone'
                  name='phone'
                  value={selectData.phone}
                  onChange={handleSelectChange}
                  className='p-2 rounded-md w-60 border border-gray-200 focus:outline-blue cursor-pointer'
                />
              </>
            )}

            {!['admin', 'supervisor'].some((role) => getUser()?.roles?.includes(role)) ? null : selectData?.selected === 'sales' && (
              <>
                <div className='flex items-center gap-2'>
                  <select
                    value={selectData.sales}
                    onChange={handleSelectChange}
                    name='sales'
                    className='border border-gray-200 p-2 rounded-md focus:outline-blue variant-scroll'>
                    <option value=''>select sales</option>
                    {dataSales?.map((sales, index) => (
                      <option key={index} value={sales}>{sales}</option>
                    ))}
                  </select>

                  <input
                    type='text'
                    placeholder='Start date'
                    name='start'
                    max={maxDate}
                    value={selectData.start}
                    onChange={handleSelectChange}
                    className='p-2 rounded-md w-28 border border-gray-200 focus:outline-blue cursor-pointer'
                    onFocus={(e) => (e.target.type = 'date')}
                    onBlur={(e) => (e.target.type = 'text')}
                  />
                  <input
                    type='text'
                    name='end'
                    max={maxDate}
                    value={selectData.end}
                    onChange={handleSelectChange}
                    className='p-2 rounded-md border border-gray-200 w-28 focus:outline-blue cursor-pointer'
                    placeholder='End date'
                    onFocus={(e) => (e.target.type = 'date')}
                    onBlur={(e) => (e.target.type = 'text')}
                  />
                </div>
              </>
            )}

            {selectData?.selected === 'tanggal' && (
              <>
                <div className='w-60 flex items-center gap-2'>
                  <input
                    type='text'
                    placeholder='Start date'
                    name='start'
                    max={maxDate}
                    value={selectData.start}
                    onChange={handleSelectChange}
                    className='p-2 rounded-md w-28 border border-gray-200 focus:outline-blue cursor-pointer'
                    onFocus={(e) => (e.target.type = 'date')}
                    onBlur={(e) => (e.target.type = 'text')}
                  />
                  <input
                    type='text'
                    name='end'
                    max={maxDate}
                    value={selectData.end}
                    onChange={handleSelectChange}
                    className='p-2 rounded-md border border-gray-200 w-28 focus:outline-blue cursor-pointer'
                    placeholder='End date'
                    onFocus={(e) => (e.target.type = 'date')}
                    onBlur={(e) => (e.target.type = 'text')}
                  />
                </div>
              </>
            )}

            <button
              className='bg-blue-500 disabled:bg-gray-500 rounded-md p-2 text-white hover:bg-blue-400 transition-all uppercase'
              onClick={() => !isLoading && submitFilterHandler()}>
              {isLoading ? 'Loading...' : 'Filter'}
            </button>
          </div>
          {history.location.pathname != "/management-sales/oms-accounts" && (
            <LangcodeSwitch />
          )}
        </div>

        <div className="flex space-x-3 items-center">
          {isUseAssigneSales && ['admin', 'supervisor'].some((role) => getUser()?.roles?.includes(role)) &&

            <>
              <AssigneSales
                dataSales={dataSales}
                setChangeData={setChangeData}
                showModalAssigneSales={showModalAssigneSales}
                setShowModalAssigneSales={setShowModalAssigneSales}
                showModalResponse={showModalResponse}
                setShowModalResponse={setShowModalResponse}
              />
            </>
          }

          {selectData?.selected === 'tanggal' && (
            <div className='flex space-x-3  md:items-center text-sm'>
              <p className='line-clamp-1'>
                Page : {isPage} of {totalPages}
              </p>
              <select
                className='border border-gray-300 p-1 rounded-md focus:outline-blue '
                value={selectData?.limit}
                onChange={handleSelectChange}
                name='limit'
                selected='0'>
                <option disabled value='0'>
                  Data Limit
                </option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='200'>200</option>
                <option value='300'>300</option>
              </select>
              <button
                disabled={disabledPrev}
                className={`flex text-sm p-2  text-white rounded-md cursor-pointer ${disabledPrev ? ' bg-gray-200' : 'bg-blue-300'
                  } `}
                onClick={() => {
                  if (isPage > 1) {
                    setIsPage((prev) => prev - 1);
                  }
                }}>
                <div>
                  <ArrowBackIosIcon style={{ fontSize: '12px' }} />
                </div>
                <p>Prev</p>
              </button>

              <button
                disabled={disabledNext}
                className={`flex text-sm p-2  text-white rounded-md cursor-pointer ${disabledNext ? ' bg-gray-200' : 'bg-blue-300'
                  } `}
                onClick={() => {
                  setIsPage((prev) => prev + 1);
                }}>
                <p>Next</p>
                <div>
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: '12px',
                      marginLeft: '3px',
                      marginRight: '-3px',
                    }}
                  />
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FilterNewCustomer;
