import Layout from 'components/Layout';
import { useEffect, useState } from 'react';
// import { getUser } from 'helpers/parseJWT';
import CategoriesForm from 'components/Cms/CategoriesForm/index';
import { getCategoryDataIndo } from 'service/api';
import { handleBigIntResponse } from 'helpers/handleBigInt';
import { SubRoutesCms as SUBROUTES } from 'components/Cms/SubRoutesCms';
import useQueryparams from 'hooks/use-queryparams';
import { useHistory, useLocation } from 'react-router-dom';
const Categories = () => {
  const location = useLocation();
  let params = useQueryparams();
  let history = useHistory();
  const [categories, setCategories] = useState();
  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    if (!params.get("source")) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("source", "procimart");
      history.push({ search: searchParams.toString() });
    } else {
      _getCategoryData();
    }
  }, [trigger, params]);

  const _getCategoryData = async () => {
    const response = await getCategoryDataIndo({
      source: params.get("source")
    });
    if (response?.status === 200) {
      const resultText = await response.text();
      const resultResponse = handleBigIntResponse(resultText);
      setCategories(resultResponse.data);
    }
  };

  return (
    <Layout routes={SUBROUTES()} title="CMS">
      {categories && <CategoriesForm categories={categories} updateData={_getCategoryData} setTrigger={setTrigger} />}
    </Layout>
  );
};

export default Categories;
