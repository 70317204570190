import {
  CircularProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelPO from './CancelPO';
import Notes from '../../General/Notes';
// import AdjustmentModal from './Adjustment/AdjustmentModal';
import DetailProductTable from './DetailProductTable';
import DetailModal from '../../General/DetailOrderModal/DetailModal';
import { getPoPaidData, synchronizeData } from 'service/api';
import PaginationFilter from '../../General/PaginationFilter';
import InputTracking from './InputTracking';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { useLocation } from 'react-router-dom';
import { getUser } from 'helpers/parseJWT';
import swal from 'sweetalert';
import Filters from 'components/General/Filters';
import BadgeLevel from 'components/General/BadgeLevel';
import { censorPhoneNumber } from 'helpers/cencorPhoneNumber';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { triggerState } from 'atom';
import { getLangcode } from 'atom';
import FilterPurchasing from 'components/General/FilterPurchasing';
import BrandLogo from 'components/General/BrandLogo';

export default function PoPaidTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  const [update, setUpdate] = useState(false);
  const [idSo, setIdSo] = useState('');
  const [toko, setToko] = useState('all');
  const [auto, setAuto] = useState('');
  const [canceled, setCanceled] = useState(null)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [date, setDate] = useState({
    start: '',
    end: '',
    sorting: 'asc'
  });
  const [pageInfo, setPageInfo] = useState({});
  const [selectedIdPo, setSelectedIdPo] = useState([]);
  const [selectIdPoAll, setSelectIdPoAll] = useState(false);
  const [trigger, setTrigger] = useRecoilState(triggerState);
  const [sla, setSla] = useState('');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [langcodes, setLangcodes] = useRecoilState(getLangcode);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const { t } = useTranslation();

    

  const fetchOrderData = async (limit, page, id, toko, auto, canceled, slaProps, langcode) => {
    setIsLoading(true);

    const tokoParams = toko !== '' && toko !== null ? `toko=${toko}&` : '';
    const autoParams = auto !== null && auto !== '' ? `auto=${auto}&` : '';
    const idParams = id && id !== '' ? `id=${id}&` : '';
    const startParams = date.start !== '' ? `start=${date.start}&` : '';
    const endParams = date.end !== '' ? `end=${date.end}&` : '';
    const sortParams = date.sorting && date.sorting !== '' ? `sort=${date.sorting}&` : '';
    const canceledParams = canceled && canceled !== '' ? `canceled=${canceled}&` : '';
    const slaParams = slaProps !== '' && slaProps !== undefined && slaProps !== null ? `sla=${slaProps}&` : '';
    const langcodePrams = langcodes && langcodes !== '' ? `langcode=${langcodes}` : '';

    const params =
      `page=${page}&limit=${limit}&` +
      tokoParams +
      autoParams +
      idParams +
      startParams +
      endParams +
      sortParams +
      canceledParams +
      slaParams +
      langcodePrams;

    const data = await getPoPaidData(params);
    if (data?.status === 200) {
      const newFormat = [];
      for (let i = 0; i < data?.data?.data?.customer.collection.length; i++) {
        const customer = data?.data?.data?.customer.collection[i];
        const order = data?.data?.data?.orders.collection[i];
        const idOrder = data?.data?.data?.idOrders.collection[i];
        const finance = data?.data?.data?.finance?.collection[i];
        newFormat.push({ customer, order, idOrder, finance });
      }
      setDataOrder(newFormat);
      setPageInfo({
        dataInPage: data?.data?.dataInPage,
        totalData: data?.data?.totalData,
        totalPage: data?.data?.totalPage,
      });
    } else {
      setDataOrder([])
      setPageInfo({})
    }
    setSelectIdPoAll(false)
    setSelectedIdPo([])
    setIsLoading(false);
    setIsFilterApplied(false)
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    let slaValue = query.get('sla');
    let idValue = query.get('id')

    if (search) {
      setIdSo(query.get('id'))
      setToko(query.get('toko'))
      setAuto(query.get('auto'))
      setCanceled(query.get('canceled'))
      setSla(query.get('sla'))
      if (slaValue === "true" || slaValue === "false" || idValue) {
        fetchOrderData(limit, page, query.get('id'), query.get('toko'), query.get('auto'), query.get('canceled'), slaValue, langcodes);
      } else {
        fetchOrderData(limit, page, query.get('id'), query.get('toko'), query.get('auto'), query.get('canceled'), slaValue, langcodes);
      }
    } else {
      setSla(slaValue);
      if (isFilterApplied) {
        fetchOrderData(limit, page, idSo, toko, auto, canceled, langcodes);
      } else {
        fetchOrderData(limit, page, idSo, toko, auto, canceled);
      }
    }
  }, [search, update, limit, page])

  const searchOrderByFilters = (id, toko, auto) => {
    setLimit(5)
    setPage(1)
    let slaValue = query.get('sla');
    setIsOpen(false)
    fetchOrderData(5, 1, id, toko, auto, canceled, slaValue, langcodes);
    setIsFilterApplied(true)
  };

  const syncData = async (isMultiple, id, toko) => {
    swal({
      title: 'Syncing data..',
      onBeforeOpen() {
        swal.showLoading()
      },
      onAfterClose() {
        swal.hideLoading()
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      closeOnEnter: false,
      buttons: false
    })

    let payload = []
    if (isMultiple) {
      payload = selectedIdPo
    } else {
      payload = [
        {
          toko: toko,
          id_po: id,
        }
      ]
    }
    if (isMultiple) {
      localStorage.setItem('synching_po', true)
      setTrigger((prev) => !prev);
    }

    setTimeout(async () => {
      const response = await synchronizeData(JSON.stringify(payload))
      if (response.status === 200) {
        setUpdate(prev => !prev)
        if (isMultiple) {
          // if (isMultiple && payload.length > 1) {
          swal('Sync Data', 'Synchronize Data in Progress', 'warning')
          return
        }
        if (response.is_changed) {
          swal('Data Synchronize Success', response.message, 'success')
        } else {
          swal('Data Synchronize Success', response.message, 'success')
        }
        // swal.close()
      } else {
        swal('Error', "Failed to Synchronize Data!", 'error')
      }
    }, 3500)
  }

  const set_SelectedIdPo = (selectAll, checked, idPo, toko) => {
    if (checked) {
      if (selectAll) {
        const selected_id_po = []

        dataOrder.forEach((i) => {
          if (['1688'].includes(i.idOrder.toko)) {
            selected_id_po.push({ toko: i.idOrder.toko, id_po: i.idOrder.id_po })
          }
        })
        setSelectedIdPo(selected_id_po)
        setSelectIdPoAll(true)
      } else {
        const isExist = selectedIdPo?.filter(i => i.id_po === idPo).length
        if (!isExist) {
          setSelectedIdPo(prev => [
            ...prev,
            {
              toko: toko,
              id_po: idPo,
            }
          ]
          )
        }
      }
    } else {
      if (selectAll) {
        setSelectedIdPo([])
        setSelectIdPoAll(false)
      } else {
        setSelectedIdPo((prev) => prev.filter(i => i.id_po !== idPo))
      }
    }
  }

  const renderLoading = isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        padding: '10px',
        borderRadius: 2,
        backgroundColor: 'white',
        marginBottom: 1,
      }}>
      <div className="flex space-x-3 items-center w-full bg-blue-100 p-3 my- rounded-md">
        <CircularProgress size={20} />
        <p className="text-gray-500 text-sm ">Updating data ...</p>
      </div>
    </Box>
  ) : null;

  const Row = ({ rowData }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow style={{ display: 'flex' }}>
          {getUser().roles.includes('admin') &&
            <TableCell
              style={{
                width: '5%',
              }}>
              <input type='checkbox' name='select-id-po'
                checked={["1688"].includes(rowData?.idOrder?.toko) ?
                  selectedIdPo.map(i => i.id_po).includes(rowData?.idOrder?.id_po) :
                  false
                }
                onChange={(e) => set_SelectedIdPo(false, e.target.checked, rowData?.idOrder?.id_po, rowData?.idOrder?.toko)}
                disabled={!["1688"].includes(rowData?.idOrder?.toko)}
              />
            </TableCell>
          }
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '15%',
            }}>
            <div className="text-sm flex h-full flex-col justify-between">
              <div>
                <div className="flex items-center space-x-2">
                  <DetailModal id={rowData.idOrder.id_so} />
                  <div className="bg-yellow-500 text-white px-2 py-1 rounded-bl-lg">{rowData?.idOrder?.toko || "Custom"}</div>
                </div>
                <div>
                  {typeof rowData?.idOrder?.pcm === "string" && rowData?.idOrder?.pcm.trim() !== "" && (
                    <div className='text-base text-blue-800 font-semibold uppercase'>
                      ({rowData?.idOrder?.pcm})
                    </div>
                  )}
                </div>
                <div>
                  {rowData.idOrder.id_po && (
                    <p className="font-semibold">
                      PO : {rowData.idOrder.id_po}
                    </p>
                  )}
                  {rowData.idOrder.paymentDate && (
                    <p>{t("Payment Date")} : {rowData.idOrder.paymentDate}</p>
                  )}
                  {rowData.idOrder.keterangan && (
                    <p>{t("Note")} :{rowData.idOrder.keterangan}</p>
                  )}
                  {rowData?.idOrder?.po_payment && (
                    <p>PO Payment : {rowData?.idOrder?.po_payment}</p>
                  )}
                </div>
                <div className="flex space-x-2 items-center my-1">
                  {rowData.idOrder.airplane ? (
                    <>
                      <FlightTakeoffIcon />
                      <p className="text-xs font-semibold">Air Freight</p>
                    </>
                  ) : (
                    <>
                      <DirectionsBoatIcon />
                      <p className="text-xs font-semibold">Sea Freight</p>
                    </>
                  )}
                </div>
                {rowData?.idOrder?.auto &&
                  <div className="w-full text-xs text-center mb-2 line-clamp-1" title={rowData?.idOrder?.auto}>{rowData?.idOrder?.auto}</div>
                }
                {rowData?.idOrder?.mytask &&
                  <div className="task-status px-2 py-1 text-xs text-white text-center bg-yellow-500 rounded-md mb-2">{rowData?.idOrder?.mytask}</div>
                }
                {rowData?.finance?.income >= 3000000 &&
                  <div className="task-status px-2 py-1 text-xs text-white text-center bg-green-500 rounded-md">Big Order</div>
                }
                {rowData.idOrder.notes &&
                  rowData.idOrder.notes.slice(0, 3).map((note, id) => (
                    <div
                      className="flex items-center text-xs justify-between"
                      key={id}>
                      <em className="line-clamp-1 w-14">{note.date}</em>
                      <p className="line-clamp-1">{note.note}</p>
                      <p>{note.sales}</p>
                    </div>
                  ))}
              </div>
              <div className="text-center">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(!open);
                  }}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <span className="text-xs">{t("Details")}</span>
              </div>
            </div>
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '17%',
            }}>
            <div className="text-sm">
              <p>{rowData.customer.name}</p>
              <p className="break-words line-clamp-1">
                {rowData.customer.email}
              </p>
              <p>{getUser().isBlur ? censorPhoneNumber(rowData?.customer?.phone) : rowData?.customer?.phone}</p>
              <p className="line-clamp-3">{rowData.customer.address}</p>
              <em className="line-clamp-1">{rowData.customer.courier}</em>
              <BadgeLevel level={rowData?.idOrder?.level} />
              <div className="flex flex-col gap-1 mt-1">
              <BrandLogo dataSource={rowData?.idOrder?.source} />
              </div>
            </div>
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '28%',
            }}>
            <div className="flex flex-col items-center  space-y-2">
              {rowData?.order?.slice(0, 3).map((order, id) => {
                return (
                  <div className="text-sm space-y-1" key={id}>
                    <p className="line-clamp-1">{order.name}</p>
                    <div className="flex items-center justify-between font-semibold">
                      <p>{order.variant}</p>
                      <p>{order.qty} pcs</p>
                      <p>
                        RMB{' '}
                        {order.customerPrice.toLocaleString('id-ID')}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '20%',
            }}>
            <div className="text-sm space-y-2">
              {rowData.finance?.income ? (
                <div className="flex justify-between">
                  <span>{t("Total Income")}:</span>
                  <span>{rowData.finance?.income.toLocaleString('id-ID')}</span>
                </div>
              ) : null}
              {rowData.finance?.expense && (
                <div>
                  <div className="flex justify-between">
                    <span>{t("Total Expense")}:</span>
                    <span>
                      {rowData.finance?.expense?.toLocaleString('id-ID')}
                    </span>
                  </div>
                  <div className="text-xs">(without Indonesian logistic)</div>
                </div>
              )}
              <hr />
              {rowData.finance?.percentage && (
                <div className="flex justify-between">
                  <span>{t("Percentage")}:</span>
                  <span>{rowData.finance?.percentage} %</span>
                </div>
              )}
              {rowData.finance?.profit && (
                <div className="flex justify-between">
                  <span>{t("Profit")}:</span>
                  <span
                    className={`${rowData.finance?.income >= rowData.finance?.expense
                      ? 'text-green-500'
                      : 'text-red-500'
                      } font-semibold`}>
                    {rowData.finance?.profit.toLocaleString('id-ID')}
                  </span>
                </div>
              )}

              {rowData?.idOrder?.is_packing_kayu ?
                <>
                  <hr />
                  <div className="flex">
                    <span className='px-2 py-1 text-xs text-white bg-red-500 rounded-md'>Wooden Packing</span>
                  </div>
                </>
                : null}

              {rowData?.idOrder?.adjustment_status === "open" ||
                rowData?.idOrder?.adjustment_status === "unpaid" ? (
                <>
                  <hr />
                  <div className='flex gap-1'>
                    <span className="py-1 text-red-600 font-semibold">
                      {t("Warning")}:
                    </span>
                    <div className='border rounded-md text-orange-500 border-orange-500 w-fit flex items-center px-2'>Waiting Paid Adjusment</div>
                  </div>
                </>
              ) : rowData?.idOrder?.warning ? (
                <>
                  <hr />
                  <span className="py-1 text-red-600 font-semibold">
                    {t("Warning")}: {rowData?.idOrder?.warning_message}
                  </span>
                </>
              ) : undefined}

              {rowData?.idOrder?.last_update && (
                <>
                  <hr />
                  <span className="py-1">
                    {t("Last Update")}: {rowData?.idOrder?.last_update}
                  </span>
                </>
              )}
            </div>
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '20%',
            }}>
            <div className="space-y-2 mx-3">
              {getUser().roles.includes('admin') &&
                <InputTracking
                  id={rowData.idOrder.id_so}
                  invoiceNumber={rowData.idOrder.id_po}
                  setUpdate={setUpdate}
                />
              }
              {/* <AdjustmentModal
                id_so={rowData.idOrder.id_so}
                id_po={rowData.idOrder.id_po}
                setUpdate={setUpdate}
                dataOrder={rowData.order}
              /> */}
              <Notes
                id={rowData?.idOrder?.id_so}
                id_group={rowData?.idOrder?.id_group}
                endpoint="po_paid"
                task_status={rowData?.idOrder?.mytask}
                totalNotes={rowData?.idOrder?.total_notes}
                totalNotesByRole={rowData?.idOrder?.total_notes_group_by_role}
                setUpdate={setUpdate}
              />
              {getUser().roles.includes('admin') &&
                <CancelPO
                  id_so={rowData.idOrder.id_so}
                  invoice={rowData.idOrder.id_po}
                  dataOrder={rowData.order}
                  totalRefund={rowData.idOrder.total}
                  setUpdate={setUpdate}
                />
              }
              {(getUser().roles.includes('admin') && ["1688"].includes(rowData?.idOrder?.toko)) &&
                <div
                  className="py-2 px-5 border text-blue-500  text-sm border-blue-500 rounded-md text-center cursor-pointer"
                  onClick={() => syncData(false, rowData?.idOrder?.id_po, rowData?.idOrder?.toko)}
                >
                  {t("Sync")}
                </div>
              }
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Detail Product
                </Typography>
                <DetailProductTable
                  dataOrder={rowData.order}
                  link={rowData.idOrder.link}
                  id_so={rowData.idOrder.id_so}
                  notesProduct={rowData.idOrder.notes}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <div className='pt-2'>
        {renderLoading}
      </div>
      <FilterPurchasing
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        filterToko={true}
        idSo={idSo}
        setIdSo={setIdSo}
        toko={toko}
        setToko={setToko}
        auto={auto}
        setAuto={setAuto}
        date={date}
        setDate={setDate}
        setUpdate={setUpdate}
        actionSubmit={searchOrderByFilters}
      />
      <div className="flex items-center justify-between bg-white p-2 px-3 rounded-md my-2">
        <div className="flex items-center gap-x-3 text-sm font-semibold">
          {/* <Filters
            filterToko={true}
            idSo={idSo}
            toko={toko}
            setToko={setToko}
            auto={auto}
            setAuto={setAuto}
            date={date}
            setDate={setDate}
            setUpdate={setUpdate}
            actionSubmit={searchOrderByFilters}
          /> */}
        </div>
        <div className='flex gap-2'>
          {pageInfo.dataInPage
            ? `${t("Showing data")} ${pageInfo.dataInPage} ${t("of")} ${pageInfo.totalData}`
            : null}
          <PaginationFilter
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalPage={pageInfo.totalPage}
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ display: 'flex' }}>
              {getUser().roles.includes('admin') &&
                <TableCell
                  style={{
                    width: '5%',
                  }}>
                  <input type='checkbox' name='select-id-po-all'
                    checked={selectIdPoAll}
                    onChange={(e) => set_SelectedIdPo(true, e.target.checked)}
                  />
                </TableCell>
              }
              <TableCell
                style={{
                  width: '15%',
                }}>
                ID Orders
              </TableCell>
              <TableCell
                style={{
                  width: '17%',
                }}>
                Customer
              </TableCell>
              <TableCell
                style={{
                  width: '28%',
                }}>
                Order
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}>
                Finance
              </TableCell>
              <TableCell
                style={{
                  width: '20%',
                }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataOrder.length > 0 ? (
              <>
                {dataOrder.map((row, id) => (
                  <Row rowData={row} key={id} />
                ))}
                {dataOrder.length <= 3 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ height: '50vh' }}></TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  style={{ height: '70vh' }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!selectedIdPo?.length ? null :
        <div className="fixed bg-green-500 text-white whitespace-nowrap px-5 py-2 rounded-lg hover:transform hover:scale-105 cursor-pointer"
          style={{ bottom: '1.5rem', right: '3rem', boxShadow: '2px 2px 5px #888', textShadow: '1px 1px 3px black' }}
          onClick={() => syncData(true)}
        >
          Sync Orders
        </div>
      }
    </>
  );
}