export const generateRandomString = (length = 10) => {
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters.charAt(randomIndex);
	}
	return result;
};

export const objToSearchParam = (obj) => Object.entries(obj)
	.filter(([, val]) => val) // Remove falsy values
	.map(([key, val]) => key + "=" + val)
	.join('&');


const LIST_SALES = [
	{
		"nama": "Alviana",
		"user": "alvi"
	},
	{
		"nama": "Aprini",
		"user": "aprini"
	},
	{
		"nama": "Aris Kurniawan",
		"user": "aris_k"
	},
	{
		"nama": "Ayu Lestari",
		"user": "ayu"
	},
	{
		"nama": "CIPTA MUKTI AJI",
		"user": "cipta"
	},
	{
		"nama": "David Georahman",
		"user": "david"
	},
	{
		"nama": "Dicky Purba Tjakradipura",
		"user": "dickypurba"
	},
	{
		"nama": "Eko Johanes Putra",
		"user": "ekoputra"
	},
	{
		"nama": "Fajar Kurniawan",
		"user": "fajar"
	},
	{
		"nama": "Fandi Juliandi",
		"user": "fandi"
	},
	{
		"nama": "Firly Nur Apriyanti",
		"user": "firly"
	},
	{
		"nama": "Fitrah Eka Syawal",
		"user": "fitrah"
	},
	{
		"nama": "Fauziah Hashari",
		"user": "hasari"
	},
	{
		"nama": "Herry lauren",
		"user": "herry"
	},
	{
		"nama": "Moh Ihsan",
		"user": "ihsan"
	},
	{
		"nama": "Intan Dwi Purbianingrum",
		"user": "intan_ae"
	},
	{
		"nama": "Johan",
		"user": "johan"
	},
	{
		"nama": "Karunia Laia",
		"user": "karunia"
	},
	{
		"nama": "Kinanthi Citra Wenny",
		"user": "kinanthi"
	},
	{
		"nama": "Lasarah Mutmainah",
		"user": "lasarah"
	},
	{
		"nama": "Lina Aprilia",
		"user": "lina"
	},
	{
		"nama": "Lola Anggelia Putri",
		"user": "lola"
	},
	{
		"nama": "Maria Desnanda",
		"user": "maria"
	},
	{
		"nama": "Milla Rosa Febiyanti",
		"user": "milla"
	},
	{
		"nama": "Nikko Satrio Pratomo",
		"user": "nikko"
	},
	{
		"nama": "Novelia",
		"user": "novel"
	},
	{
		"nama": "Permadi Nawa Tunggal",
		"user": "permadi"
	},
	{
		"nama": "Nurvia Maghfiroh Putri",
		"user": "putri"
	},
	{
		"nama": "Ranti",
		"user": "ranti"
	},
	{
		"nama": "RENDY YANUAR",
		"user": "rendy_y"
	},
	{
		"nama": "Rohanah",
		"user": "rohanah"
	},
	{
		"nama": "Ryan Novaldi",
		"user": "ryan"
	},
	{
		"nama": "Saiful Anwar",
		"user": "saiful"
	},
	{
		"nama": "Welfi Yusra",
		"user": "welfi"
	}
]

export const csvToJson = (csvString) => {
	try {
		const rows = csvString.trim().split('\n');
		if (rows.length < 2) return [];

		const headers = rows[0].split(',').map(header => header.trim().toLowerCase());
		const result = new Array(rows.length - 1);

		for (let i = 1; i < rows.length; i++) {
			const row = rows[i];
			const values = row.split(',');
			const obj = {};

			for (let j = 0; j < headers.length; j++) {
				obj[headers[j]] = values[j]?.trim() || '';
			}

			result[i - 1] = obj;
		}

		return result;
	} catch (error) {
		throw new Error('Failed to parse CSV file');
	}
};
