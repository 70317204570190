import { Tooltip } from '@mui/material';
import Layout from 'components/Layout';
import { useEffect, useState } from 'react';
import { getAllCustomerRequest } from 'service/api';
import swal from 'sweetalert';
import AllCustomerRequestTable from 'components/ManagementSales/AllCustomerRequest/AllCustomerRequestTable';
import FilterAllCustomerRequest from 'components/ManagementSales/AllCustomerRequest/FilterAllCustomerRequest';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { getLangcode } from 'atom';

const AllCustomerRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterWithSales, setFilterWithSales] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [update, setUpdate] = useState(false);

  const [selectData, setSelectData] = useState({
    sales: '',
    status: '',
    start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  });

    

  const fetchData = async () => {
    const { start, end, status, sales } = selectData;

    setIsLoading(true);

    const salesPayload = sales !== '' ? `sales=${sales}&` : '';
    const statusPayload = status !== '' ? `status=${status}&` : '';
    const startDate = start !== '' ? `start=${start}&` : '';
    const endDate = end !== '' ? `end=${end}` : '';
    // const langcodeParams = langcodes !== '' ? `&langcode=${langcodes}` : '';

    let params = salesPayload + statusPayload + startDate + endDate;

    const response = await getAllCustomerRequest(params);
    if (response?.status === 200) {
      setData(response.data);
    } else {
      swal('Oops', `${response?.message}`, 'error');
    }
    if(response.sales?.length !== 0) {
      setFilterWithSales(response.sales.map((user) => user.user));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (dataFiltered?.length !== 0) {
      setData(dataFiltered);
    }
  }, [dataFiltered]);

  useEffect(() => {
    fetchData();
  }, [update]);

  return (
    <Layout routes={SUBROUTES()} title="CRM">
      <Tooltip title="Refresh table" placement="right">
        <button
          onClick={fetchData}
          className="my-4 bg-white w-52 p-2  rounded-md cursor-pointer text-center">
          All Customer Request
        </button>
      </Tooltip>

      <FilterAllCustomerRequest
        filterWithSales={filterWithSales}
        setIsFiltered={setIsFiltered}
        setDataFiltered={setDataFiltered}
        setIsLoading={setIsLoading}
        selectData={selectData}
        setSelectData={setSelectData}
        isLoading={isLoading}
      />
      <AllCustomerRequestTable
        dataTable={data}
        isLoading={isLoading}
        setUpdate={setUpdate}
        update={update}
        selectData={selectData}
        setSelectData={setSelectData}
      />
    </Layout>
  );
};

export default AllCustomerRequest;
