import { update } from "lodash";
import { useEffect, useState } from "react";
import Barcode from "react-barcode";

const options = {
  width: 1.8,
  height: 50,
  format: "CODE128",
  displayValue: true,
  fontOptions: "",
  font: "Arial",
  fontWeight:"bold",
  textAlign: "center",
  textPosition: "bottom",
  textMargin: 2,
  fontSize: 24,
  background: "#ffffff",
  lineColor: "#000000",
  margin: 5,
  marginTop: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginRight: undefined,
};

export default function BarcodeCardList({
  data,
  // customerData,
  isLoading,
  customBarcode,
  idBarcode,
}) {
  const [currentDateTime, setCurrentDateTime] = useState({
    date: "",
    time: "",
  });

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const date = now.toLocaleDateString();
      const time = now.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });
      setCurrentDateTime({ date, time });
    };
    updateDateTime();
  }, []);
  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }
  if (!data) {
    return null;
  }

  // const change_id = idBarcode;
  // const totalLoop = customBarcode;

  const updateData = data;
  // const updateData = data?.map((item) => {
  //   if (change_id === "0" && totalLoop === 0) {
  //     return item;
  //   } else if (item?.data_karton?.some((datas) => datas.id_karton === change_id)) {
  //     return {
  //       ...item,
  //       data_karton: [
  //         ...Array.from({ length: totalLoop }, (_, i) => ({
  //           alamat: item.alamat,
  //           container: item.container,
  //           id_so: item.id_so,
  //           category: item.category,
  //           id_karton: change_id,
  //           index_id : i + 1,
  //           id: item.id,
  //           customer: item.customer,
  //           id_palet: item.id_palet,
  //           kecamatan: item.kecamatan,
  //           keterangan: item.keterangan,
  //           kota: item.kota,
  //           kuantiti: item.kuantiti,
  //           kurir: item.kurir,
  //           need_eta_driver: item.need_eta_driver,
  //           provinsi: item.provinsi,
  //           status: item.status,
  //           tanggal: item.tanggal,
  //           telepon: item.telepon,
  //           total_karton: item.total_karton,
  //           updated: item.updated
  //         })),
  //       ],
  //     };
  //   } else {
  //     return item;
  //   }
  // });
  return (
    <>
      {updateData?.karton?.length > 0 ? (
        <>
          {updateData?.karton?.map((datas, index) => {
            if (updateData?.kurir?.includes("jabodetabek")) {
              return <BarcodeCardJabodetabek key={index} data={data} index={index} datas={datas} customBarcode={customBarcode} updateData={updateData} currentDateTime={currentDateTime} />
            } else {
              return <BarcodeCardJNT key={index} index={index} datas={datas} customBarcode={customBarcode} updateData={updateData} currentDateTime={currentDateTime} />
            }
          })}
        </>
      ) : (
        <>
          <div className="print-label-container w-96 p-2 mb-5" style={{
            width: '24rem',
            padding: '0.5rem',
            marginBottom: '1.25rem',
            position: 'relative',
            pageBreakAfter: 'always',
          }}>
            <div className="logo flex items-center gap-4" style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}>
              <img
                src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/logoOCIpng2.png`}
                alt="Ocistok"
                className={customBarcode?.trim() === '' || customBarcode === undefined ? `w-36` : "w-24"}
                style={{
                  width: customBarcode?.trim() === '' || customBarcode === undefined ? '9rem' : '6rem',
                }}
              />
              {!updateData?.is_cicilan ? null : (
                <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem' }}>INSTALMENT</div>
              )}
              {updateData?.karton?.length === 0 ? null : (
                <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem', textDecoration: 'underline' }}>
                  REPACK {updateData?.karton?.length}
                </div>
              )}
            </div>
            <div className={customBarcode?.trim() === '' || customBarcode === undefined ? 'hidden' : 'text-xl font-bold flex'}>
              <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Total Print</div>
              <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
              <div className="ml-1 text-2xl">{data?.karton?.length}/{customBarcode}</div>
            </div>
            <div className="flex justify-between p-2 border border-gray-400 rounded-lg mt-3">
              <div className="category">
                <div className="font-bold text-2xl" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem' }}>Kategori</div>
                <div className="category-id text-3xl font-bold" style={{ fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: 'bold' }}>
                  {data.wilayah}
                </div>
                <div className="text-lg font-semibold" style={{ fontSize: '1.125rem', lineHeight: '1.75rem', fontWeight: '600' }}>{data.tlc}</div>
              </div>
              <div className="barcode">
                <p className="text-black font-bold" style={{ color: 'black', fontWeight: 'bold' }}>
                  Barcode Belum Tersedia
                </p>
              </div>
            </div>

            <div className="leading-5 relative pb-4" style={{ lineHeight: '1.25rem', position: 'relative', paddingBottom: '1rem' }}>
              <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Jasa Kirim</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">{data.kurir}</div>
              </div>
              <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Kota</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">{data.kabupaten}</div>
              </div>
              <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0 text-[1.25rem]" style={{ width: '7.5rem', flexShrink: 0, fontSize: '1.25rem', }}>Total Karton</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="text-[1.4rem]" style={{ fontSize: '1.8rem' }}>
                  {data?.karton?.length >= 0
                    ? data.karton.length
                    : "Produk Belum dikemas"}
                </div>
              </div>

              <hr className="border-t-2 border-gray-300" style={{ borderTopWidth: '2px', borderColor: 'rgba(209, 213, 219, 1)' }} />

              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>
                  Jenis Transaksi
                </div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="uppercase" style={{ textTransform: 'uppercase' }}>
                  {data.is_cod ? 'COD' : 'NON COD'}
                </div>
              </div>
              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Packing Kayu</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">
                  {data.is_packing_kayu ? "Yes" : "No"}
                </div>
              </div>
              {/* <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
      <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Volume</div>
      <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
      <div className="">
        {data.volume && (data.volume / 1000000).toFixed(4)} M<sup>3</sup>
      </div>
    </div> */}
              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Nama</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">{data.nama}</div>
              </div>
              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Telepon</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">{data.telepon}</div>
              </div>
              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Alamat</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">{data.alamat}</div>
              </div>
              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Sales</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="capitalize" style={{ textTransform: 'capitalize' }}>{data.sales}</div>
              </div>

              <hr className="border-t-2 border-gray-300" style={{ borderTopWidth: '2px', borderColor: 'rgba(209, 213, 219, 1)' }} />

              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Pengirim</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">Ocistok.com</div>
              </div>
              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Kota</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">Cengkareng</div>
              </div>
              <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
                <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Telp</div>
                <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
                <div className="">021222302662</div>
              </div>

              <div className="absolute right-0 bottom-0 font-bold text-center p-2 bg-white border border-gray-400 rounded-lg" style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '0.25rem',
                backgroundColor: 'white',
                borderWidth: '1px',
                borderColor: 'rgba(156, 163, 175, 1)',
                borderRadius: '0.5rem',
              }}>
                <div className="font-normal" style={{ fontWeight: 'normal' }}>{updateData?.marking_code}</div>
                <div className="text-3xl leading-8" style={{ fontSize: '1.875rem', lineHeight: '2rem' }}>{updateData?.wilayah}</div>
              </div>
            </div>
            <div className="greet text-[.75rem] font-semibold leading-4" style={{ fontSize: '0.75rem', fontWeight: '600', lineHeight: '1rem' }}>
              Terima Kasih Sudah Berbelanja Di Ocistok.com <br />
              Kontak CS melalui WhatsApp 0812-1000-1808
            </div>
          </div>
        </>
      )}
    </>
  );
}

function BarcodeCardJNT({ datas, customBarcode, updateData, currentDateTime, index }) {
  return (
    <div
      className="print-label-container w-96 p-2 mb-5 relative"
      style={{
        width: "24rem",
        padding: "0.5rem",
        marginBottom: "1.25rem",
        position: "relative",
        pageBreakAfter: "always",
      }}
    >
      {/* <div className="w-full flex justify-evenly bg-red-200 gap-12">
        <img
          src={`/jntcargo_logoxocistok.png`}
          alt="Ocistok"
          className={customBarcode?.trim() === '' || customBarcode === undefined ? `w-36` : "w-24"}
          style={{
            width: customBarcode?.trim() === '' || customBarcode === undefined ? '9rem' : '6rem',
          }}
        />
        <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem'}}>
            KIRIM 
          </div>
        {!updateData?.is_cicilan ? null : (
          <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem' }}>
            INSTALMENT
          </div>
        )}
        {updateData?.karton?.length === 0 ? null : (
          <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem', textDecoration: 'underline' }}>
            REPACK {updateData?.karton?.length}
          </div>
        )}
      </div> */}
      <div className="flex justify-around">
        <img
          src={`/jntcargo_logoxocistok.png`}
          alt="Ocistok"
          className={
            customBarcode?.trim() === "" || customBarcode === undefined
              ? `w-44`
              : "w-24"
          }
        />
        <div>
          <p className="text-xs">
            waktu cetak : {currentDateTime.date} {currentDateTime.time}
          </p>
          <p className="text-xs">outlet pengirim : </p>
          <div
            className="font-bold text-2xl mb-1 text-center"
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              lineHeight: "2rem",
              marginBottom: "0.25rem",
            }}
          >
            KIRIM
          </div>
        </div>
      </div>
      <h1 className="text-center text-2xl font-bold py-4">
        <b className="hidden">TGR-TGR132A</b>
      </h1>

      <div className="flex">
        <div className="w-5/6 border-t-2 border-r-2 border-black">
          <div className="flex justify-center barcode border-b-2 border-black">
            <div className="barcode h-20">
              {datas ? (
                <div className="hidden">
                  <Barcode value={datas} {...options} />
                </div>
              ) : (
                <p
                  className="text-black font-bold"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Barcode Belum Tersedia
                </p>
              )}
            </div>
          </div>
          <div className="text-sm font-bold space-y-1 border-b-2 border-black py-2">
            <p>TO :</p>
            <div className="flex justify-between">
              <p>{updateData?.nama}</p>
              <p className="pr-4">{updateData?.telepon}</p>
            </div>
            <div className="space-x-1">
              {updateData?.alamat} {updateData.kecamatan}{" "}
              {updateData.provinsi} {updateData.kode_pos}
            </div>
          </div>
          <div className="text-sm font-bold space-y-1 border-b-2 border-black">
            <p>Berat : </p>
            <div className="flex">
              <div className="min-w-1/2">
                <p>
                  Volume :{" "}
                  {updateData?.volume &&
                    (updateData?.volume / 1000000).toFixed(4)}{" "}
                  M<sup>3</sup>
                </p>
              </div>
              <div>
                <p className="line-clamp-1 pl-3">
                  PCS : 0{index + 1}/0{updateData.total_karton}
                </p>
              </div>
            </div>
          </div>
          <div className="text-sm font-bold space-y-1 pb-2">
            <p>From: OCI</p>
            <div className="flex space-x-1">
              <p>keterangan: </p>
              <h1 className="text-2xl font-black">
                {" "}
                {updateData.id_so}, Carton: {index + 1} Category:{" "}
                {updateData.wilayah}
              </h1>
            </div>
          </div>
        </div>
        <div className="w-1/6 border-t-2 border-black inv">
          <p className="invisible"></p>
        </div>
      </div>
      <div className={`w-full mt-2 flex ${updateData.is_cicilan ? "justify-between" : "justify-end"}`}>
        {updateData.is_cicilan && (
          <div className="text-red-500 flex items-center text-lg font-bold">
            <h1>INSTALLMENT</h1>
          </div>)}
        <div>
          {datas ? (
            <div>
              <Barcode value={datas} {...options} />
            </div>
          ) : (
            <p
              className="text-black font-bold"
              style={{ color: "black", fontWeight: "bold" }}
            >
              Barcode Belum Tersedia
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

function BarcodeCardJabodetabek({ index, datas, data, customBarcode, updateData, }) {
  return (
    <div
      className="print-label-container w-96 p-2 mb-5 relative" style={{
        width: '24rem',
        padding: '0.5rem',
        marginBottom: '1.25rem',
        position: 'relative',
        pageBreakAfter: 'always',
      }}>
      <div className="logo flex items-center gap-4" style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
      }}>
        {
          !updateData?.is_cicilan || updateData?.karton?.length === 0 && (
            <img
              src={`https://s3x.ocistok.com/ocistok/content/foto/Logo-Ocistok-Black-White2.png`}
              alt="Ocistok"
              className={`${customBarcode?.trim() === '' || customBarcode === undefined ? `w-36` : "w-24"} mx-auto`}
              style={{
                width: customBarcode?.trim() === '' || customBarcode === undefined ? '9rem' : '6rem',
              }}
            />
          )
        }
        <div className="flex justify-between w-full">
          {updateData?.karton?.length === 0 ? null : (
            <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.3rem', lineHeight: '2rem', marginBottom: '0.25rem', textDecoration: 'underline' }}>
              REPACK {updateData?.karton?.length}
            </div>
          )}
          {!updateData?.is_cicilan ? null : (
          <div className="font-bold text-2xl mb-1 text-red-500" style={{ fontWeight: 'bold', fontSize: '1.3rem', lineHeight: '2rem', marginBottom: '0.25rem' }}>
            INSTALMENT
          </div>
          )}
        </div>
      </div>

      <div className={customBarcode?.trim() === '' || customBarcode === undefined ? 'hidden' : 'text-xl font-bold flex'}
        style={{
          fontSize: '1.25rem',
          lineHeight: '1.75rem',
          fontWeight: 'bold',
          display: 'flex',
          display: customBarcode?.trim() === '' || customBarcode === undefined ? 'none' : ''
        }}
      >
        <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0, }}>Total Print</div>
        <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
        <div className="ml-1" style={{ marginLeft: '0.25rem' }}>{index + 1}/{customBarcode}</div>
      </div>

      <div className="flex flex-col justify-between mt-3" style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        {updateData.is_packing_kayu ? (
          <div className="bg-black my-1 mx-1 text-center font-semibold text-sm" style={{
            backgroundColor: 'rgba(0, 0, 0, 1)',
            margin: '0.25rem',
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          }}>
            <span className="text-white text-3xl font-bold" style={{
              color: 'white',
              fontSize: '1.875rem',
              lineHeight: '2.25rem',
              fontWeight: 'bold',
            }}>WOODEN PACKAGING</span>
          </div>)
          : null}

        <div className="flex justify-between p-2 border border-gray-400 rounded-lg" style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0.5rem',
          borderWidth: '1px',
          borderColor: 'rgba(156, 163, 175, 1)',
        }}>
          <div className="category">
            <div className="font-bold text-2xl" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem' }}>Kategori</div>
            <div className="category-id text-3xl font-bold" style={{ fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: 'bold' }}>
              {updateData?.wilayah}
            </div>
            <div className="text-lg font-semibold" style={{ fontSize: '1.125rem', lineHeight: '1.75rem', fontWeight: '600' }}>{updateData?.tlc}</div>
          </div>
          <div className="barcode">
            {datas ? (
              <div>
                <Barcode value={datas} {...options} />
                {updateData?.pcm?.trim() !== "" && (
                <p className="font-bold ">{updateData.pcm}</p>)}
              </div>
            ) : (
              <p className="text-black font-bold" style={{ color: 'black', fontWeight: 'bold' }}>
                Barcode Belum Tersedia
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="leading-5 relative pb-4" style={{ lineHeight: '1.25rem', position: 'relative', paddingBottom: '1rem' }}>
        <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
          <div className="w-[7.5rem] flex-shrink-0 " style={{ width: '9rem', flexShrink: 0, fontSize: '1rem', }}>Delivery Service</div>
          <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
          <div className="" style={{ fontSize: '1rem' }}>{updateData?.kurir}</div>
        </div>
        <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
          <div className="w-[7.5rem] flex-shrink-0 " style={{ width: '9rem', flexShrink: 0, fontSize: '1rem', }}>City</div>
          <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
          <div className="" style={{ fontSize: '1rem' }}>{updateData?.kabupaten}</div>
        </div>
        <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
          <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0, fontSize: '1rem', }}>Total Cartons</div>
          <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
          <div className="" style={{ fontSize: '1rem' }}>
            {updateData?.karton?.length >= 0
              ? updateData?.karton?.length
              : "Produk Belum dikemas"}
          </div>
        </div>

        <hr className="border-t-2 border-gray-300" style={{ borderTopWidth: '2px', borderColor: 'rgba(209, 213, 219, 1)' }} />

        {/* <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '.85rem', fontWeight: '600', display: 'flex' }}>
            <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>
              Transaction Type
            </div>
            <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
            <div className="uppercase" style={{ textTransform: 'uppercase' }}>
              {data.is_cod ? 'COD' : 'NON COD'}
            </div>
          </div> */}
        {/* <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
            <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Wooden Packing</div>
            <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
            <div className="">
              {updateData.is_packing_kayu ? "Yes" : "No"}
            </div>
          </div> */}
        {/* <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
            <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Volume</div>
            <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
            <div className="">
              {updateData?.volume && (updateData?.volume / 1000000).toFixed(4)} M<sup>3</sup>
            </div>
          </div> */}
        <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
          <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Name</div>
          <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
          <div className="">{updateData?.nama}</div>
        </div>
        <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
          <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Telephone</div>
          <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
          <div className="">{updateData?.telepon}</div>
        </div>
        <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
          <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Address</div>
          <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
          <div className="">{updateData?.alamat}</div>
        </div>
        <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
          <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>District</div>
          <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
          <div className="">{updateData?.kecamatan}</div>
        </div>
        <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
          <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Province</div>
          <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
          <div className="">{updateData?.provinsi}</div>
        </div>
        {/* <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
            <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Sales</div>
            <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
            <div className="capitalize" style={{ textTransform: 'capitalize' }}>{updateData?.sales}</div>
          </div> */}

        <hr className="border-t-2 border-gray-300" style={{ marginTop: "4px", borderTopWidth: '2px', borderColor: 'rgba(209, 213, 219, 1)' }} />

        {/* <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
            <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Pengirim</div>
            <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
            <div className="">Ocistok.com</div>
          </div>
          <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
            <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Kota</div>
            <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
            <div className="">Cengkareng</div>
          </div>
          <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.85rem', fontWeight: '600', display: 'flex' }}>
            <div className="w-[7.5rem] flex-shrink-0" style={{ width: '9rem', flexShrink: 0 }}>Telp</div>
            <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
            <div className="">021222302662</div>
          </div> */}

        {/* <div className="absolute right-0 bottom-0 font-bold text-center p-2 bg-white border border-gray-400 rounded-lg" style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '0.25rem',
            backgroundColor: 'white',
            borderWidth: '1px',
            borderColor: 'rgba(156, 163, 175, 1)',
            borderRadius: '0.5rem',
          }}>
            <div className="font-normal" style={{ fontWeight: 'normal' }}>{updateData?.marking_code}</div>
            <div className="text-3xl leading-8" style={{ fontSize: '1.875rem', lineHeight: '2rem' }}>{updateData?.wilayah}</div>
          </div> */}
      </div>
      {/* <div className="greet text-[.75rem] font-semibold leading-4" style={{ fontSize: '0.75rem', fontWeight: '600', lineHeight: '1rem' }}>
          Terima Kasih Sudah Berbelanja Di Ocistok.com <br />
          Kontak CS melalui WhatsApp 0812-1000-1808
        </div> */}
    </div>
  )
}


// {updateData?.karton?.length > 0 ? (
//   <>
//     {updateData?.karton.map((datas, index) => (
//       <div
//         key={datas}
//         className="print-label-container w-96 p-2 mb-5 relative" style={{
//           width: '24rem',
//           padding: '0.5rem',
//           marginBottom: '1.25rem',
//           position: 'relative',
//           pageBreakAfter: 'always',
//         }}>
//         <div className="logo flex items-center gap-4" style={{
//           display: 'flex',
//           alignItems: 'center',
//           gap: '1rem',
//         }}>
//           <img
//             src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/logoOCIpng2.png`}
//             alt="Ocistok"
//             className={customBarcode?.trim() === '' || customBarcode === undefined ? `w-36` : "w-24"}
//             style={{
//               width: customBarcode?.trim() === '' || customBarcode === undefined ? '9rem' : '6rem',
//             }}
//           />
//           {!updateData?.is_cicilan ? null : (
//             <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem' }}>
//               INSTALMENT
//             </div>
//           )}
//           {updateData?.karton?.length === 0 ? null : (
//             <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem', textDecoration: 'underline' }}>
//               REPACK {updateData?.karton?.length}
//             </div>
//           )}
//         </div>

//         <div className={customBarcode?.trim() === '' || customBarcode === undefined  ? 'hidden' : 'text-xl font-bold flex'}
//           style={{
//             fontSize: '1.25rem',
//             lineHeight: '1.75rem',
//             fontWeight: 'bold',
//             display: 'flex',
//             display: customBarcode?.trim() === '' || customBarcode === undefined  ? 'none' : ''
//           }}
//         >
//           <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0,}}>Total Print</div>
//           <div className="mr-1" style={{ marginRight: '0.25rem'}}>:</div>
//           <div className="ml-1" style={{ marginLeft: '0.25rem'}}>{index+1}/{customBarcode}</div>
//         </div>

//         <div className="flex flex-col justify-between mt-3" style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'space-between',
//         }}>
//           {updateData.is_packing_kayu ? (
//             <div className="bg-black my-1 mx-1 text-center font-semibold text-sm" style={{
//               backgroundColor: 'rgba(0, 0, 0, 1)',
//               margin: '0.25rem',
//               textAlign: 'center',
//               fontWeight: 600,
//               fontSize: '0.875rem',
//               lineHeight: '1.25rem',
//             }}>
//               <span className="text-white text-3xl font-bold" style={{
//                 color: 'white',
//                 fontSize: '1.875rem',
//                 lineHeight: '2.25rem',
//                 fontWeight: 'bold',
//               }}>WOODEN PACKAGING</span>
//             </div>)
//           : null}

//           <div className="flex justify-between p-2 border border-gray-400 rounded-lg" style={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             padding: '0.5rem',
//             borderWidth: '1px',
//             borderColor: 'rgba(156, 163, 175, 1)',
//           }}>
//             <div className="category">
//               <div className="font-bold text-2xl" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem' }}>Kategori</div>
//               <div className="category-id text-3xl font-bold" style={{ fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: 'bold' }}>
//                 {updateData?.wilayah}
//               </div>
//               <div className="text-lg font-semibold" style={{ fontSize: '1.125rem', lineHeight: '1.75rem', fontWeight: '600' }}>{updateData?.tlc}</div>
//             </div>
//             <div className="barcode">
//               {datas ? (
//                 <div>
//                   <Barcode  value={datas} {...options} />
//                 </div>
//               ) : (
//                 <p className="text-black font-bold" style={{ color: 'black', fontWeight: 'bold' }}>
//                   Barcode Belum Tersedia
//                 </p>
//               )}
//             </div>
//           </div>
//         </div>

//         <div className="leading-5 relative pb-4" style={{ lineHeight: '1.25rem', position: 'relative', paddingBottom: '1rem'}}>
//           <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0 text-[1.4rem]" style={{ width: '7.5rem', flexShrink: 0, fontSize: '1.1rem', }}>Jasa Kirim</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="text-[1.4rem]" style={{ fontSize: '1.1rem' }}>{updateData?.kurir}</div>
//           </div>
//           <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0 text-[1.4rem]" style={{ width: '7.5rem', flexShrink: 0, fontSize: '1.1rem', }}>Kota</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="text-[1.4rem]" style={{ fontSize: '1.1rem' }}>{updateData?.kabupaten}</div>
//           </div>
//           <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0 text-[1.25rem]" style={{ width: '7.5rem', flexShrink: 0, fontSize: '1.25rem', }}>Total Karton</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="text-[1.4rem]" style={{ fontSize: '1.8rem' }}>
//               {updateData?.karton?.length >= 0
//                 ? updateData?.karton?.length
//                 : "Produk Belum dikemas"}
//             </div>
//           </div>

//           <hr className="border-t-2 border-gray-300" style={{ borderTopWidth: '2px', borderColor: 'rgba(209, 213, 219, 1)'}} />

//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>
//               Jenis Transaksi
//             </div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="uppercase" style={{ textTransform: 'uppercase' }}>
//               {data.is_cod ? 'COD' : 'NON COD'}
//             </div>
//           </div>
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Packing Kayu</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">
//               {updateData.is_packing_kayu ? "Yes" : "No"}
//             </div>
//           </div>
//           {/* <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Volume</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">
//               {updateData?.volume && (updateData?.volume / 1000000).toFixed(4)} M<sup>3</sup>
//             </div>
//           </div> */}
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Nama</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">{updateData?.nama}</div>
//           </div>
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Telepon</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">{updateData?.telepon}</div>
//           </div>
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Alamat</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">{updateData?.alamat}</div>
//           </div>
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Kecamatan</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">{updateData?.kecamatan}</div>
//           </div>
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Provinsi</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">{updateData?.provinsi}</div>
//           </div>
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Sales</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="capitalize" style={{ textTransform: 'capitalize' }}>{updateData?.sales}</div>
//           </div>

//           <hr className="border-t-2 border-gray-300" style={{ borderTopWidth: '2px', borderColor: 'rgba(209, 213, 219, 1)'}}/>

//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Pengirim</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">Ocistok.com</div>
//           </div>
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Kota</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">Cengkareng</div>
//           </div>
//           <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//             <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Telp</div>
//             <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//             <div className="">021222302662</div>
//           </div>

//           <div className="absolute right-0 bottom-0 font-bold text-center p-2 bg-white border border-gray-400 rounded-lg" style={{
//             position: 'absolute',
//             right: 0,
//             bottom: 0,
//             fontWeight: 'bold',
//             textAlign: 'center',
//             padding: '0.25rem',
//             backgroundColor: 'white',
//             borderWidth: '1px',
//             borderColor: 'rgba(156, 163, 175, 1)',
//             borderRadius: '0.5rem',
//           }}>
//             <div className="font-normal" style={{ fontWeight: 'normal' }}>{updateData?.marking_code}</div>
//             <div className="text-3xl leading-8" style={{ fontSize: '1.875rem', lineHeight: '2rem' }}>{updateData?.wilayah}</div>
//           </div>
//         </div>
//         <div className="greet text-[.75rem] font-semibold leading-4" style={{ fontSize: '0.75rem', fontWeight: '600', lineHeight: '1rem' }}>
//           Terima Kasih Sudah Berbelanja Di Ocistok.com <br />
//           Kontak CS melalui WhatsApp 0812-1000-1808
//         </div>
//       </div>
//     ))}
//   </>
// ) : (
// <div className="print-label-container w-96 p-2 mb-5" style={{
//   width: '24rem',
//   padding: '0.5rem',
//   marginBottom: '1.25rem',
//   position: 'relative',
//   pageBreakAfter: 'always',
// }}>
//   <div className="logo flex items-center gap-4" style={{
//       display: 'flex',
//       alignItems: 'center',
//       gap: '1rem',
//     }}>
//     <img
//       src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/logoOCIpng2.png`}
//       alt="Ocistok"
//       className={customBarcode?.trim() === '' || customBarcode === undefined ? `w-36` : "w-24"}
//       style={{
//         width: customBarcode?.trim() === '' || customBarcode === undefined ? '9rem' : '6rem',
//       }}
//     />
//     {!updateData?.is_cicilan ? null : (
//       <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem' }}>INSTALMENT</div>
//     )}
//     {updateData?.karton?.length === 0 ? null : (
//       <div className="font-bold text-2xl mb-1" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', marginBottom: '0.25rem', textDecoration: 'underline' }}>
//         REPACK {updateData?.karton?.length}
//       </div>
//     )}
//   </div>
//   <div className={customBarcode?.trim() === '' || customBarcode === undefined ? 'hidden' : 'text-xl font-bold flex'}>
//     <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Total Print</div>
//     <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//     <div className="ml-1 text-2xl">{data?.karton?.length}/{customBarcode}</div>
//   </div>
//   <div className="flex justify-between p-2 border border-gray-400 rounded-lg mt-3">
//     <div className="category">
//       <div className="font-bold text-2xl" style={{ fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem' }}>Kategori</div>
//       <div className="category-id text-3xl font-bold" style={{ fontSize: '1.875rem', lineHeight: '2.25rem', fontWeight: 'bold' }}>
//         {data.wilayah}
//       </div>
//       <div className="text-lg font-semibold" style={{ fontSize: '1.125rem', lineHeight: '1.75rem', fontWeight: '600' }}>{data.tlc}</div>
//     </div>
//     <div className="barcode">
//       <p className="text-black font-bold" style={{ color: 'black', fontWeight: 'bold' }}>
//         Barcode Belum Tersedia
//       </p>
//     </div>
//   </div>

//   <div className="leading-5 relative pb-4" style={{ lineHeight: '1.25rem', position: 'relative', paddingBottom: '1rem' }}>
//     <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Jasa Kirim</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">{data.kurir}</div>
//     </div>
//     <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Kota</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">{data.kabupaten}</div>
//     </div>
//     <div className="text-2xl font-bold flex" style={{ fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 'bold', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0 text-[1.25rem]" style={{ width: '7.5rem', flexShrink: 0, fontSize: '1.25rem', }}>Total Karton</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="text-[1.4rem]" style={{ fontSize: '1.8rem' }}>
//         {data?.karton?.length >= 0
//           ? data.karton.length
//           : "Produk Belum dikemas"}
//       </div>
//     </div>

//     <hr className="border-t-2 border-gray-300" style={{ borderTopWidth: '2px', borderColor: 'rgba(209, 213, 219, 1)'}}/>

//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>
//         Jenis Transaksi
//       </div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="uppercase" style={{ textTransform: 'uppercase' }}>
//         {data.is_cod ? 'COD' : 'NON COD'}
//       </div>
//     </div>
//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Packing Kayu</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">
//         {data.is_packing_kayu ? "Yes" : "No"}
//       </div>
//     </div>
//     {/* <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Volume</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">
//         {data.volume && (data.volume / 1000000).toFixed(4)} M<sup>3</sup>
//       </div>
//     </div> */}
//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Nama</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">{data.nama}</div>
//     </div>
//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Telepon</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">{data.telepon}</div>
//     </div>
//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Alamat</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">{data.alamat}</div>
//     </div>
//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Sales</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="capitalize" style={{ textTransform: 'capitalize' }}>{data.sales}</div>
//     </div>

//     <hr className="border-t-2 border-gray-300" style={{ borderTopWidth: '2px', borderColor: 'rgba(209, 213, 219, 1)'}}/>

//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Pengirim</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">Ocistok.com</div>
//     </div>
//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Kota</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">Cengkareng</div>
//     </div>
//     <div className="text-[.75rem] font-semibold flex" style={{ fontSize: '0.75rem', fontWeight: '600', display: 'flex' }}>
//       <div className="w-[7.5rem] flex-shrink-0" style={{ width: '7.5rem', flexShrink: 0 }}>Telp</div>
//       <div className="mr-1" style={{ marginRight: '0.25rem' }}>:</div>
//       <div className="">021222302662</div>
//     </div>

//     <div className="absolute right-0 bottom-0 font-bold text-center p-2 bg-white border border-gray-400 rounded-lg" style={{
//       position: 'absolute',
//       right: 0,
//       bottom: 0,
//       fontWeight: 'bold',
//       textAlign: 'center',
//       padding: '0.25rem',
//       backgroundColor: 'white',
//       borderWidth: '1px',
//       borderColor: 'rgba(156, 163, 175, 1)',
//       borderRadius: '0.5rem',
//     }}>
//       <div className="font-normal" style={{ fontWeight: 'normal' }}>{updateData?.marking_code}</div>
//       <div className="text-3xl leading-8" style={{ fontSize: '1.875rem', lineHeight: '2rem' }}>{updateData?.wilayah}</div>
//     </div>
//   </div>
//   <div className="greet text-[.75rem] font-semibold leading-4" style={{ fontSize: '0.75rem', fontWeight: '600', lineHeight: '1rem' }}>
//     Terima Kasih Sudah Berbelanja Di Ocistok.com <br />
//     Kontak CS melalui WhatsApp 0812-1000-1808
//   </div>
// </div>
// )}
