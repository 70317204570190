import React from 'react'

const BrandLogo = (dataSource) => {
    return (
        <div className="flex flex-col gap-1">
            {dataSource.dataSource === "procimart" ? (
                <div className="w-20">
                    <img
                        alt="language"
                        className="border border-orange-500 w-fit p-1 rounded-md"
                        src={"https://s3x.ocistok.com/ocistok/content/foto/id-logo-procimart_cropped.webp"} />
                </div>
            ) : dataSource.dataSource === "martkita" ? (
                <div className="w-20">
                    <img
                        alt="language"
                        className="border border-red-500 w-fit p-1 rounded-md"
                        src={"https://s3x.ocistok.com/ocistok/content/foto/Markita-merah-png-3.png"} />
                </div>
            ) : (
                <>
                    <div className="w-20">
                        <img
                            alt="language"
                            className="border border-orange-500 w-fit rounded-md"
                            src={"/logo-ocistok.svg"} />
                    </div>
                </>
            )}
        </div>
    )
}

export default BrandLogo